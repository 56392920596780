import React, { useState, useEffect } from 'react';

import TemporaryDrawer from "../../main/TemporayDrawer";
import Footer from "../../main/Footer";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useNavigate, useLocation } from 'react-router-dom';
import PreviewIcon from '@mui/icons-material/Preview';
import Button from '@mui/material/Button';
import { TextField } from '@material-ui/core';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FaturaService from '../../api/services/FaturaService';
import { getCodEmpUser, isAdmin } from '../../api/services/AuthService';
import moment from 'moment'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid, ptBR } from '@mui/x-data-grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeaderFilterSearch from '../../main/CardHeaderFilterSearch';
import { motion } from "framer-motion";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import ReportFaturaService from '../../api/services/ReportFaturaService';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import PaidIcon from '@mui/icons-material/Paid';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import ClearIcon from '@mui/icons-material/Clear';
import Tooltip from '@mui/material/Tooltip';

import dayjs from 'dayjs';


import styled from "styled-components";

const TextFieldCustom = styled(TextField)`

  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: #4682B4;
    }
  }
`;

const CustomDesktopDatePicker = styled(DesktopDatePicker)`
& .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: #4682B4;
    }
  }
`;

const Header = styled(Box)`   
    margin-top:10px;
`

const IconeNotAvaible = styled(ClearIcon)`
    color:#B22222;
`

const Layout = styled.div`   
    background-color:white;
    font-weight: bold;
    width:95%;
    height:900px;
    top:80px;
    margin-top:20px;
    align-items: center;
    justify-content: center;
    margin:0 auto;
    margin-top:100px;
`

const Titulo = styled(Typography)`
    color:#4682B4;
    font-weight:bold;
    width:100%;
    margin-botton:20px;    
`
const IconeCsv = styled(TextSnippetIcon)`
    color:green;
`
const IconePdf = styled(PictureAsPdfIcon)`
    color:#B22222;
`
const IconeBoleto = styled(PaidIcon)`
    color:#000000;
`
const Tabela = styled.div`
    height: 650px;
    overflow: auto;
    width: 100%;
    margin-top:10px;
    padding-bottom:20px;
    
`

const ListarFatura = () => {
    const [numeroFatura, setNumeroFatura] = useState(sessionStorage.getItem("numeroFatura"));
    const [produto, setProduto] = useState(sessionStorage.getItem("produto") && sessionStorage.getItem("produto") !== '' ? sessionStorage.getItem("produto") : "TODOS");
    const [open, setOpen] = useState(false);
    const [faturas, setFaturas] = useState([]);
    const [dataInicio, setDataInicio] = useState(null);
    const [dataFim, setDataFim] = useState(null);
    const [tipoData, setTipoData] = useState("TODAS");
    const [errorMessage, setErrorMessage] = useState("");
    const [openSnack, setOpenSnack] = useState(false);
    const [tipoSnack, setTipoSnack] = useState("");
    const [pagamento, setPagamento] = useState("AMBOS");
    const [empresa, setEmpresa] = useState("");
    const [minDateVencimento, setMinDateVencimento] = useState(null)

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const faturaService = new FaturaService();
    let navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location && location.state) {
            clear();
            if (location.state.produto) {
                setProduto(location.state.produto);
                setNumeroFatura(location.state.invoiceNumber);
                if (location.state.dataInicio && location.state.dataFim) {
                    setDataInicio(location.state.dataInicio);
                    setDataFim(location.state.dataFim);
                    setTipoData("DATA_EMISSAO");
                    listarSimplificado({
                        invoiceType: location.state.produto,
                        empfat: getCodEmpUser(),
                        invoiceNumber: location.state.invoiceNumber,
                        disabledAFaturar: location.state.disabledAFaturar,
                        dataInicio: location.state.dataInicio,
                        dataFim: location.state.dataFim,
                        tipoData: 'DATA_EMISSAO',
                    });
                } else {
                    listarSimplificado({
                        invoiceType: location.state.produto,
                        empfat: getCodEmpUser(),
                        invoiceNumber: location.state.invoiceNumber,
                        disabledAFaturar: location.state.disabledAFaturar,
                        tipoData: 'TODAS'
                    });
                }
            }

        } else {
            if (!isAdmin())
                listar();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleView = (event, cellValues) => {
        handleToggle();
        faturaService.detalhar(
            {
                invoiceNumber: cellValues.row.numfat,
                empfat: isAdmin() ? empresa : getCodEmpUser(),
                dataven: moment(cellValues.row.dataVen).format('DD/MM/YYYY'),
                codest: cellValues.row.codest,
                invoiceType: faturaService.removeAcento(cellValues.row.invoiceType).toUpperCase(),
                condpag: cellValues.row.condpag,
                valor: cellValues.row.valor,
            }
        ).then(response => {
            response.data.codest = cellValues.row.codest;
            response.data.condpag = cellValues.row.condpag;
            response.data.dataVen = moment(cellValues.row.dataVen).format('DD/MM/YYYY');
            navigate('/detalharFatura', { replace: true, state: { fatura: response.data } })
        }).catch(erro => {
            //setError(true);
        }).finally(() => {
            handleClose();
        });
    }

    const service = new ReportFaturaService();

    const downloadBoleto = (event, cellValues) => {
        setOpen(true);
        service.getFile('/getBoleto/',
            {
                invoiceNumber: cellValues.row.numfat,
            }
        ).then(response => {
            const blobFile = new Blob([response.data], { type: response.headers['content-type'] });
            let url = window.URL.createObjectURL(blobFile);
            let a = document.createElement('a');
            a.href = url;
            a.download = cellValues.row.numfat + '.pdf';
            a.click();
            setErrorMessage("Download de boleto realizado com sucesso!");
            setOpenSnack(true);
            setTipoSnack("success");
        }).catch(erro => {
            switch (erro?.response?.status) {
                case 404:
                    setErrorMessage("Boleto não encontrado na pasta designada!");
                    break;
                default:
                    setErrorMessage("Erro ao carregar o boleto!Contate o adm do sistema!");
                    break;
            }
            setOpenSnack(true);
            setTipoSnack("error");
        }).finally(() => {
            setOpen(false);
        })
    }

    const downloadPdf = (event, cellValues) => {
        setOpen(true);
        service.getFile('/getPdf/',
            {
                invoiceNumber: cellValues.row.numfat,
                empfat: getCodEmpUser(),
                dataven: moment(cellValues.row.dataVen).format('DD/MM/YYYY'),
                codest: cellValues.row.codest,
                invoiceType: faturaService.removeAcento(cellValues.row.invoiceType).toUpperCase(),
                condpag: cellValues.row.condpag,
                valor: cellValues.row.valor,
            }
        ).then(response => {
            const blobFile = new Blob([response.data], { type: response.headers['content-type'] });
            let url = window.URL.createObjectURL(blobFile);
            let a = document.createElement('a');
            a.href = url;
            a.download = cellValues.row.numfat === 0 ? 'aFaturar.pdf' : cellValues.row.numfat + '.pdf';
            a.click();
            setErrorMessage("Download do pdf da fatura realizado com sucesso!");
            setOpenSnack(true);
            setTipoSnack("success");
        }).catch(erro => {
            console.log('Error name: ', erro.name)
            console.log('Error message: ', erro.message)
            console.log('Error stack: ', erro.stack)
        }).finally(() => {
            setOpen(false);
        })
    }

    const downloadPlanilha = (event, cellValues) => {
        setOpen(true);
        service.getFile('/getCsv/',
            {
                invoiceNumber: cellValues.row.numfat,
                empfat: getCodEmpUser(),
                dataven: moment(cellValues.row.dataVen).format('DD/MM/YYYY'),
                codest: cellValues.row.codest,
                invoiceType: faturaService.removeAcento(cellValues.row.invoiceType).toUpperCase(),
                condpag: cellValues.row.condpag,
                valor: cellValues.row.valor,
            }
        ).then(response => {
            const blobFile = new Blob([response.data], { type: response.headers['content-type'] });
            let url = window.URL.createObjectURL(blobFile);
            let a = document.createElement('a');
            a.href = url;
            a.download = 'planilha_' + cellValues.row.numfat + '.csv';
            a.click();
        }).catch(erro => {
            console.log('Error name: ', erro.name)
            console.log('Error message: ', erro.message)
            console.log('Error stack: ', erro.stack)
        }).finally(() => {
            setOpen(false);
        })
    }

    const handleCloseSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSnack(false);
    };

    const saveStorage = () => {
        sessionStorage.setItem("numeroFatura", numeroFatura);
        sessionStorage.setItem("produto", produto);
    }

    const validarListagem = () => {
        let retorno = true;
        let message = '';
        if (isAdmin() && !empresa) {
            message += "Selecione a empresa para exibir as faturas!\n";
            retorno = false;
        }
        if ((dataInicio || dataFim) && tipoData === 'TODAS') {
            message += "Selecione o Período para pesquisa!\n";
            retorno = false;
        } if (tipoData !== 'TODAS' && (!dataInicio || !dataFim)) {
            message += "Preencha o Data Início e a Data Fim!\n";
            retorno = false;
        }
        if (!retorno) {
            setErrorMessage(message);
            setOpenSnack(true);
            setTipoSnack("warning");
        }
        return retorno;
    }

    const clear = () => {
        setFaturas([]);
        setProduto("TODOS");
        setNumeroFatura("");
        clearStorage();
        setTipoData("TODAS");
        setDataInicio(null);
        setDataFim(null);
        setPagamento("AMBOS");
        setEmpresa("");
    }

    const clearStorage = () => {
        sessionStorage.setItem("numeroFatura", '');
        sessionStorage.setItem("produto", "TODOS");
    }

    const handleToggle = () => {
        setOpen(!open);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const listarSimplificado = (params) => {
        console.log(params)
        handleToggle();
        if (validarListagem()) {
            faturaService.listar(
                {
                    invoiceType: params.invoiceType,
                    invoiceNumber: params.invoiceNumber === '' ? null : params.invoiceNumber,
                    empfat: params.empfat,
                    disabledAFaturar: params.disabledAFaturar,
                    pagamento: 'AMBOS',
                    dataInicio: params.dataInicio,
                    dataFim: params.dataFim,
                    tipoData: params.tipoData,
                    cancelado: false,
                }
            ).then(response => {
                setFaturas(response.data);
                saveStorage();
            }).catch(erro => {
                console.log('Error name: ', erro.name)
                console.log('Error message: ', erro.message)
                console.log('Error stack: ', erro.stack)
            }).finally(() => {
                handleClose();
            })
        } else {
            handleClose();
        }
    };

    const listar = () => {
        handleToggle();
        if (validarListagem()) {
            faturaService.listar({
                invoiceNumber: numeroFatura === '' ? null : numeroFatura,
                invoiceType: produto === '' ? null : produto,
                cancelado: false,
                empfat: isAdmin() ? empresa : getCodEmpUser(),
                dataInicio: dataInicio,
                dataFim: dataFim,
                tipoData: tipoData,
                pagamento: pagamento,
                disabledAFaturar: false
            }).then(response => {
                setFaturas(response.data);
                saveStorage();
            }).catch(erro => {
                console.log('Error name: ', erro.name)
                console.log('Error message: ', erro.message)
                console.log('Error stack: ', erro.stack)
            }).finally(() => {
                handleClose();
            })
        } else {
            handleClose();
        }
    };

    const columns = [
        {
            field: "Detalhar",
            headerAlign: 'center',
            align: "center",
            sortable: false,
            disableColumnMenu: true,
            renderCell: (cellValues) => {
                return (
                    <Tooltip title="Detalhes da fatura" arrow>
                    <Button title="Detalhar"
                        onClick={(event) => {
                            handleView(event, cellValues)
                        }}><PreviewIcon /></Button>
                    </Tooltip>
                );
            }, width: 100,
        },
        {
            field: "PDF",
            sortable: false,
            headerAlign: 'center',
            align: "center",
            disableColumnMenu: true,
            renderCell: (cellValues) => {
                return (
                    <Tooltip title="Download do PDF" arrow>
                    <Button title="Gerar Pdf"
                        onClick={(event) => {
                            downloadPdf(event, cellValues)
                        }}><IconePdf /></Button></Tooltip>
                );
            }, width: 80,
        },
        {
            field: "Boleto",
            sortable: false,
            headerAlign: 'center',
            align: "center",
            disableColumnMenu: true,
            renderCell: (cellValues) => {
                return cellValues.row.pago === 'Não' && cellValues.row.numfat > 0 && cellValues.row.valor > 0 ? (
                    <Tooltip title="Download do boleto" arrow>
                    <Button
                        onClick={(event) => {
                            downloadBoleto(event, cellValues)
                        }}><IconeBoleto /></Button>
                    </Tooltip>
                ) : <Tooltip title="Download do boleto não disponível para esta fatura" arrow><IconeNotAvaible/></Tooltip>;
            }, width: 80,
        },
        {
            field: "CSV",
            sortable: false,
            headerAlign: 'center',
            align: "center",
            disableColumnMenu: true,
            renderCell: (cellValues) => {
                return cellValues.row.invoiceType === 'Aéreo' || cellValues.row.invoiceType === 'Aereo' ? (
                    <Tooltip title="Download Arquivo CSV de Conciliação" arrow>
                        <Button onClick={(event) => {
                            downloadPlanilha(event, cellValues)
                        }}><IconeCsv title="Download não disponível"/></Button>
                    </Tooltip>
                ) : <Tooltip title="Download não disponível para produtos terrestres" arrow><IconeNotAvaible /></Tooltip>;
            }, width: 80,
        },
        { field: 'numfat', headerName: 'Nº Fatura', width: 180, align: "center", headerAlign: "center" },
        {
            field: 'dataFatura', align: "center", headerAlign: "center", headerName: 'Data Emissão',width: 180, renderCell: (params) => {
                return params.value != null ? moment(params.value).format('DD/MM/YYYY') : "-";
            },
        },
        {
            field: 'dataVen', align: "center", headerAlign: "center", headerName: 'Data Vencimento', width: 200, renderCell: (params) => {
                return params.value != null ? moment(params.value).format('DD/MM/YYYY') : "-";
            },
        },
        {
            field: 'valor', align: "right", headerAlign: "center", headerName: 'Valor', width: 120, renderCell: (params) => {
                return params.value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
            },
        },
        { field: 'pago', headerName: 'Pago', width: 120,  align: "center", headerAlign: "center" },
        { field: 'situacao', headerName: 'Situação', width: 150,  align: "center", headerAlign: "center" },
        { field: 'invoiceType', headerName: 'Produto', width: 150, align: "center", headerAlign: "center" }
    ];

    const handleChangeDataInicio = (newValue) => {
        setDataInicio(newValue);
    };

    const handleChangeDataFim = (newValue) => {
        setDataFim(newValue);
    };

    const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPagamento(event.target.value);
    };

    return (
        <>
            <TemporaryDrawer />
            <motion.div
                className='reembolso'
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}>

                <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Layout className="container">
                    <Header>
                        <Titulo variant="h4" gutterBottom component="div">
                            LISTAGEM DE FATURAS
                        </Titulo>
                    </Header>
                    <Card sx={{ minWidth: 800, backgroundColor: "#F5F5F5", flexGrow: 1 }}>
                        <CardContent>
                            <CardHeaderFilterSearch />
                            <Box component="form">
                                <TextFieldCustom
                                    id="numeroFatura"
                                    sx={{ width: 200, backgroundColor: "#FFFF" }}
                                    label="Nº Fatura"
                                    type="text"
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    value={numeroFatura}
                                    onChange={(event) => {
                                        setNumeroFatura(event.target.value);
                                    }}
                                />
                                <FormControl sx={{ m: 1, width: 200, marginLeft: 2.5, paddingTop: 1 }} variant="outlined">
                                    <InputLabel id="labelSelectProdutos">Produto</InputLabel>
                                    <Select
                                        labelId="labelSelectProdutos"
                                        sx={{ width: 200, backgroundColor: "#FFFF" }}
                                        id="selectProdutos"
                                        value={produto}
                                        label="Status"
                                        size="small"
                                        onChange={(event) => {
                                            setProduto(event.target.value);
                                        }}
                                    >
                                        <MenuItem value={'TODOS'}>Todos</MenuItem>
                                        <MenuItem value={'AEREO'}>Aéreo</MenuItem>
                                        <MenuItem value={'TERRESTRE'}>Terrestre</MenuItem>
                                    </Select>
                                </FormControl>
                                {isAdmin() ?
                                    <TextFieldCustom
                                        id="empresaAdmin"
                                        sx={{ width: 200, marginLeft: 2, backgroundColor: "#FFFF" }}
                                        label="Empresa"
                                        type="text"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        size="small"
                                        value={empresa}
                                        onChange={(event) => {
                                            setEmpresa(event.target.value.replace(/[^0-9.]/g, ''));
                                        }}
                                    /> : null}
                                <FormControl sx={{ m: 1, marginLeft: 2.5 }}>
                                    <FormLabel id="demo-controlled-radio-buttons-group">Pagamento:</FormLabel>
                                    <RadioGroup
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={pagamento}
                                        row
                                        onChange={handleChangeRadio}
                                    >
                                        <FormControlLabel value="AMBOS" control={<Radio />} label="Ambos" />
                                        <FormControlLabel value="PAGO" control={<Radio />} label="Pagos" />
                                        <FormControlLabel value="ABERTO" control={<Radio />} label="Pendentes" />
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                            <Box component="form">
                                <FormControl sx={{ width: 200, marginTop: 2 }} variant="outlined">
                                    <InputLabel sx={{ marginTop: -1 }} id="labelSelectTipoDatas">Período</InputLabel>
                                    <Select
                                        labelId="labelSelectTipoDatas"
                                        id="selectTipoDatas"
                                        value={tipoData}
                                        label="periodo"
                                        size="small"
                                        sx={{ backgroundColor: "#FFFF" }}
                                        onChange={(event) => {
                                            if (event.target.value === 'DATA_VENCIMENTO') {
                                                setMinDateVencimento(dayjs('2023-01-01'));
                                            } else {
                                                setMinDateVencimento(null)
                                            }
                                            setTipoData(event.target.value);
                                        }}
                                    >
                                        <MenuItem value={'TODAS'}>Selecione</MenuItem>
                                        <MenuItem value={'DATA_EMISSAO'}>Data Emissão</MenuItem>
                                        <MenuItem value={'DATA_VENCIMENTO'}>Data Vencimento</MenuItem>
                                    </Select>
                                </FormControl>
                                <LocalizationProvider adapterLocale="pt-br" dateAdapter={AdapterDayjs} >
                                    <CustomDesktopDatePicker
                                        label="Data Início"
                                        inputFormat="DD/MM/YYYY"
                                        value={dataInicio}
                                        minDate={minDateVencimento}
                                        onChange={handleChangeDataInicio}
                                        renderInput={(params) => <TextField size="small" sx={{ marginTop: 2, marginLeft: 2.5, backgroundColor: "#FFFF" }}  {...params} />}
                                    />
                                </LocalizationProvider>
                                <LocalizationProvider adapterLocale="pt-br" dateAdapter={AdapterDayjs} >
                                    <CustomDesktopDatePicker
                                        label="Data Fim"
                                        inputFormat="DD/MM/YYYY"
                                        value={dataFim}
                                        onChange={handleChangeDataFim}
                                        renderInput={(params) => <TextField size="small" sx={{ marginTop: 2, marginLeft: 2.5, backgroundColor: "#FFFF" }}  {...params} />}
                                    />
                                </LocalizationProvider>
                            </Box>
                            <Button sx={{ width: 150, marginTop: 2.5}} onClick={listar} color="primary" variant="contained">Listar</Button>
                            <Button sx={{ width: 150, marginLeft: 2.5, marginTop: 2.5 }} onClick={clear} color="primary" variant="contained">Limpar</Button>
                        </CardContent>
                    </Card>
                    <Tabela>
                        <DataGrid
                            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                            rows={faturas}
                            columns={columns}
                            getRowId={(row) => row.rowId}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                        />
                    </Tabela>
                </Layout>
                <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
                    <Alert onClose={handleCloseSnack} severity={tipoSnack} sx={{ width: '100%', whiteSpace: 'pre-line' }}>
                        {errorMessage}
                    </Alert>
                </Snackbar>
            </motion.div>
            <Footer />
        </>
    );
}

export default ListarFatura;